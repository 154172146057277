import React, { useState, useEffect } from 'react';
import {useParams, Link, Redirect } from "react-router-dom";
import ReactCardFlip from 'react-card-flip';
import axios from 'axios';




export default () => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [cardData, setCardData]  = useState({"title": "", "data": []})
    const [isLoaded, setIsLoaded] = useState(false);
    const [cardItem, setCardItem] = useState({"front":"", "back": ""})
    const [dataError, setDataError] = useState(false);

    const {name} = useParams();



    if (!isLoaded) {
        axios.get(`/cards/${name}.json`)
        .then(res => {
            setCardData(res.data);
        })
        .catch(error => {
            setDataError(true)
        });
    }
    
/*     if (dataError)
        return <Redirect to='/'/>; */
    return (
        <div>
            
            <header className="header"><Link className="backLink" to="/">Visza a kategóriákhoz</Link>{cardData.title}</header>
        
            <div className="container card-container test-card">
                
                {cardData.data.length>0&&<ul>
                    {cardData.data.map((value, index) => {
                        return <li key={index}><div dangerouslySetInnerHTML={{__html: "Front: "+value.front}}></div> <div dangerouslySetInnerHTML={{__html: "Back: "+value.back}}></div> </li>
                        })}
                </ul>}
            </div>
            <div id="overlay" className={(cardData.data.length===0)?'show':'hide'}>
                <div className="preloader-2">
                    <span className="line line-1"></span>
                    <span className="line line-2"></span>
                    <span className="line line-3"></span>
                    <span className="line line-4"></span>
                    <span className="line line-5"></span>
                    <span className="line line-6"></span>
                    <span className="line line-7"></span>
                    <span className="line line-8"></span>
                    <span className="line line-9"></span>
                    <div>Adatok betöltése</div>
                </div>
            </div> 
        </div>
    );
}