import React from 'react';
import logo from './logo.svg';
import list from './list.json'
import {Link} from 'react-router-dom'

const Selector = () => {
    return (
        <div className="row">
            <header className="App-header col-sm-12">
                <img src={logo} className="App-logo" alt="logo" />
                <h3>Válassz kategóriát</h3>
            </header>
            <section className="col-12 row row justify-content-around">
                {list.map((value, index) => {
                return <Link to={`/${value.file}`} className={((value.color !== undefined)?" card-"+value.color:'')+" col-3 card-item"} key={index}>{value.title}</Link>
                })}
            </section>
        </div>
    );
}

export default Selector;