import React from 'react';
import './App.css';
import {BrowserRouter as Router,Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Selector from './Selector';
import Card from './Card';
import Test from './Test';

function App() {
  return (
    <Router>
      <Switch>
          <Route  path={`/test/:name`}   component={Test} />
          <Route  path={`/:name`}   component={Card} />
          
          <Route path="/">
            <Selector />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
