import React, { useState, useEffect } from 'react';
import {useParams, Link, Redirect } from "react-router-dom";
import ReactCardFlip from 'react-card-flip';
import axios from 'axios';
import MathJax from 'react-mathjax-preview'




export default () => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [cardData, setCardData]  = useState({"title": "", "data": []})
    const [isLoaded, setIsLoaded] = useState(false);
    const [cardItem, setCardItem] = useState({"front":"", "back": ""})
    const [dataError, setDataError] = useState(false);

    const {name} = useParams();


    if (!isLoaded) {
        axios.get(`cards/${name}.json`)
        .then(res => {
            setCardData(res.data);
            if (res.data.data[0] !== undefined)
                setCardItem(res.data.data[Math.floor(Math.random()*res.data.data.length)]);
        })
        .catch(error => {
            setDataError(true)
        });
    }
    const newCard = () =>{
        setCardItem(cardData.data[Math.floor(Math.random()*cardData.data.length)]);
    }
    const clickCard = () =>{
        if (isFlipped)
            newCard();
        setIsFlipped(!isFlipped);
    }
    useEffect(() => {
        setIsLoaded(true);
    }, []); 
    if (dataError)
        return <Redirect to='/'/>;
    return (
        <div>
            <header className="header"><Link className="backLink" to="/">Visza a kategóriákhoz</Link>{cardData.title}</header>
        
            <div className="container card-container">
                
                {cardData.data.length>0&&<ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                <div className="card-item" onClick={clickCard} key="front" >
                        {cardItem.front}
                    </div>
            
                    <div className="card-item" onClick={clickCard} key="back">
                        <div className="card-back" dangerouslySetInnerHTML={{__html: isFlipped?cardItem.back:''}}>
                            
                        </div>
                    </div>
                </ReactCardFlip>}
            </div>
            <div id="overlay" className={(cardData.data.length===0)?'show':'hide'}>
                <div className="preloader-2">
                    <span className="line line-1"></span>
                    <span className="line line-2"></span>
                    <span className="line line-3"></span>
                    <span className="line line-4"></span>
                    <span className="line line-5"></span>
                    <span className="line line-6"></span>
                    <span className="line line-7"></span>
                    <span className="line line-8"></span>
                    <span className="line line-9"></span>
                    <div>Adatok betöltése</div>
                </div>
            </div> 
        </div>
    );
}